import React from "react";
import Page2Table from "./Page2Table";

function Page2() {
  return (
    <div>
      <Page2Table />
    </div>
  );
}

export default Page2;
